import request from '@/utils/request'


// 查询财富特权等级列表
export function listConfiguration(query) {
  return request({
    url: '/biz/wealthLevel/list',
    method: 'get',
    params: query
  })
}

// 查询财富特权等级分页
export function pageConfiguration(query) {
  return request({
    url: '/biz/wealthLevel/page',
    method: 'get',
    params: query
  })
}

// 查询财富特权等级详细
export function getConfiguration(data) {
  return request({
    url: '/biz/wealthLevel/detail',
    method: 'get',
    params: data
  })
}

// 新增财富特权等级
export function addConfiguration(data) {
  return request({
    url: '/biz/wealthLevel/add',
    method: 'post',
    data: data
  })
}

// 修改财富特权等级
export function updateConfiguration(data) {
  return request({
    url: '/biz/wealthLevel/edit',
    method: 'post',
    data: data
  })
}

// 删除财富特权等级
export function delConfiguration(data) {
  return request({
    url: '/biz/wealthLevel/delete',
    method: 'post',
    data: data
  })
}
